import React from "react"

import GlobalCollection from "../config/GlobalCollection"
import Tarieven from "../modules/tarieven/Tarieven"

const TarievenPage = () => (
  <GlobalCollection>
    <Tarieven />
  </GlobalCollection>
)

export default TarievenPage
