import React from "react"
import styled from "../../config"
import { Flex, Box } from "reflexbox"
import TextBlock from "../../components/TextBlock"
import Blob from "../../components/Blob"

const Tarieven = () => (
  <TarievenWrapper>
    <Blob cheight="250px" />
    <Flex justifyContent="center" py={5}>
      <h1>Tarieven</h1>
    </Flex>
    <Box p={3}>
      <TextBlock>
        Een consultatie voor een <b>individueel gesprek</b> kost:
      </TextBlock>
      <ul>
        <li>
          <b>€ 65</b> voor één uur
        </li>
        <li>
          <b>€ 90</b> voor anderhalf uur
        </li>
      </ul>
      <TextBlock>
        Voor <b>koppels</b>:
      </TextBlock>
      <ul>
        <li>
          <b>€ 85</b> / uur
        </li>
      </ul>
      <TextBlock>
        <b>Hartcoherentie</b>:
      </TextBlock>
      <ul>
        <li>
          4 sessies (telkens 1u) aan <b>€ 65</b> per sessie
        </li>
      </ul>
      <TextBlock>
        Ik werk samen met verschillende ziekenfondsen. De terugbetalingsregeling
        verschilt per ziekenfonds en verandert geregeld. Je informeert hiervoor
        best zelf bij uw ziekenfonds.
      </TextBlock>
      <TextBlock>
        Als je niet op een afspraak aanwezig kunt zijn, is het mogelijk die tot
        1 dag op voorhand kosteloos af te zeggen. Zo kunnen anderen uw
        geannuleerde afspraak innemen en vermijden we onnodig lange wachttijden.
        Gebeurt dit niet tijdig, wordt een administratieve kost van € 25
        aangerekend.
      </TextBlock>
    </Box>
  </TarievenWrapper>
)

export default Tarieven

const TarievenWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 10px;
`
